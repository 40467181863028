<template>
  <div class="about">
    <h1 class="intro">A little bit about me</h1>
    <p>My name is Lars Ørnlo. I currently reside in Vestfold, Norway.<br />
      I work for <a href="https://complit.no" target="_blank">Complit AS</a>, with my main tasks being:
      <ul>
        <li>Full-stack web development</li>
        <li>Cloud Infrastructure</li>
      </ul>
      Cloud Infrastructure is mostly Office 365 and Microsoft Azure.<br>
      See <a class="certification-redirect" @click='headCertifications'>Certifications</a>
      for my main areas.<br><br>
      As a full stack web developer I work with a lot of different languages, which includes but is not limited to:
      <ul>
        <li>Python</li>
        <li>HTML / CSS / JavaScript</li>
      </ul>
      I am curious by nature and explore new technology as it comes.
    </p>
  </div>
</template>
<style scoped>
.about {
  width: 500px;
  max-width: 90%;
  margin: 0 auto;
  text-align: left
}

.about {
  font-size: 17px;
}

.about > .intro {
  text-align: center;
}

.about .certification-redirect {
  cursor: pointer;
}
</style>
<script>
import router from '../router/index'

export default {
  name: 'About',
  methods: {
    headCertifications: () => {
      router.push({
        name: 'Certifications'
      })
    }
  }
}
</script>
